.project-section {
	height: calc(100vh - 58px);
	background-color: #282c34;
}

.main-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	padding: 2rem 0;
}

/* Embla carousel styles */
.embla {
	position: relative;
	width: 80%; /* Adjust the width for the carousel */
	margin: 0 auto;
}

.embla__viewport {
	overflow: hidden;
	width: 100%;
}

.embla__container {
	display: flex;
}

.embla__slide {
	min-width: 100%; /* Each slide takes full width */
	padding: 0 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Buttons for previous/next controls with SVG arrow icons */
.embla__button {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	background: rgba(0, 0, 0, 0.5);
	border: none;
	color: white;
	padding: 10px;
	cursor: pointer;
	z-index: 10;
	border-radius: 50%;
}

.embla__button--prev {
	left: 10px;
}

.embla__button--next {
	right: 10px;
}

.embla__button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
	.embla {
		width: 95%;
	}
}
