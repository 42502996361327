.project-card-horizontal-view {
	display: flex;
	flex-direction: row;
	margin: 5px;
	width: 100%;
	padding: 1.5rem;
	padding-bottom: 1.7rem;
	border: none;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: rgba(98, 54, 134, 1) 0px 0px 5px 0px,
		rgba(98, 54, 134, 1) 0px 0px 1px 0px;
}

.card-container {
	display: flex;
	flex-direction: row !important;
}
.card-content {
	width: "65%";
	height: "auto";
}
.img-section {
	width: "35%";
	height: "auto";
}

.project-card-body {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	height: 100%;
	padding: 0 !important;
}

.project-card-title {
	font-size: 25px !important;
	font-weight: bold;
	color: #ffffff;
}

.no-padding-margin {
	margin: 0 !important;
	padding: 0 !important;
}

.project-card-description {
	color: #d3d3d3;
	font-size: 1rem;
	text-align: justify;
	width: 95%;
}

.button-container {
	display: flex;
	justify-content: space-evenly;
}

button {
	background-color: #6a0dad;
	border: none;
	padding: 10px 15px;
	border-radius: 5px;
	color: white;
	font-size: 1rem;
	transition: background-color 0.3s ease;
}

button:hover {
	background-color: #551a8b;
}

.card {
	background-color: transparent !important;
}
