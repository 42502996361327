/* Home section that takes up the full viewport */
.home-section {
	height: 100vh; /* Full height */
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--background-color);
	position: relative;
	padding: 20px;
}

/* Home2 section taking up full viewport */
.home2-section {
	height: 100vh; /* Full height */
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(
		--background-color-secondary
	); /* Different background if desired */
	padding: 20px;
}

.home-img {
	background: url(/public/assets/karan.jpg);
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	box-shadow: inset 0 0 0 9px rgba(68, 9, 206, 0.3);
	order: 1;
	justify-self: center;
	width: 470px;
	height: 500px;
	animation: profile__animate 8s ease-in-out infinite 1s;
}

@keyframes profile__animate {
	0% {
		border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
	}
	50% {
		border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
	}
	100% {
		border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
	}
}

.home-scroll {
	margin-left: 9.25rem;
}

.wheel {
	animation: scroll 2s ease infinite;
}

@keyframes scroll {
	0% {
		transform: translateY(0);
	}
	30% {
		transform: translateY(3.75rem);
	}
}

.home-scroll-name {
	color: var(--title-color);
	font-weight: var(--font-medium);
	margin-right: var(--mb-0-25);
	margin-left: 2px;
}

.home-scroll-arrow {
	font-size: 1.25rem;
	color: var(--title-color);
}

@media screen and (max-width: 992px) {
	.home-content {
		grid-template-columns: 100px repeat(2, 1fr);
		column-gap: 1.25rem;
	}
	.home-hand {
		width: 26px;
		height: 26 px;
	}
	.home-subtitle {
		padding-left: 3.75rem;
		margin-bottom: var(--mb-1);
	}
	.home-subtitle::before {
		width: 42px;
		top: 0.8rem;
	}

	.home-description {
		max-width: initial;
		margin-bottom: var(--mb-2-5);
	}

	.home-img {
		width: 250px;
		height: 250px;
		box-shadow: inset 0 0 0 8px rgba(35, 39, 88, 0.3);
	}

	.home-scroll {
		margin-left: 7.5rem;
	}
}

/* For medium devices */
@media screen and (max-width: 768px) {
	.home-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 3.5rem;
	}

	.home-img {
		box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%);
		width: 200px;
		height: 200px;
		margin: 0 auto; /* Center horizontally */
	}

	.home-scroll {
		display: none;
	}
}

/* For small devices */
@media screen and (max-width: 350px) {
	.home-content {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.home-img {
		width: 180px;
		height: 180px;
		margin: 0 auto; /* Center horizontally */
	}

	.home-hand {
		width: 22px;
		height: 22px;
	}
}
