.vertical-timeline-element-content {
	background: transparent !important;
	box-shadow: none !important;
}

.vertical-timeline-element-content .vertical-timeline-element-title,
.vertical-timeline-element-content .vertical-timeline-element-subtitle {
	color: #fff; /* Adjust text color if needed */
}

.vertical-timeline-element-date {
	color: #fff; /* Adjust date color if needed */
}
